
import { getCurrentInstance } from "vue";

export let instance = getCurrentInstance();

class Router {
    updateInstance() {
        if (!instance) {
            instance = getCurrentInstance();
        }
    }
    getInstance() {
        return instance;
    }
    getVueRouter() {
        this.updateInstance();
        return instance?.appContext?.config?.globalProperties?.$router;
    }
    getQueryParam(key) {
        const vueRouter = this.getVueRouter();
        if (vueRouter) {
            return vueRouter.currentRoute.value.query[key];
        } else {
            return new URLSearchParams(window.location.search).get(key);
        }
    }
}

const router = new Router();

export default router;

export function getVueRouter() {

}

export function getQueryParam(...args) {
    return router.getQueryParam(...args);
}
